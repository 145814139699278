import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { InputBox, MessageBox, SplashScreen } from "..";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { WebSocketHandlers } from "../../../../../services/web-sockets";
import { CustomDispatch } from "../../../../../helpers";
import {
  clearMessages,
  createChatRequest,
  getMessagesRequest,
  recieveMessageRequest,
  sendMessageRequest,
} from "../../../../../redux/slicers/chat";
import {
  MESSAGE_TIMEOUT,
  THREE_MINUTE_TIMEOUT_MODELS,
  FORTY_FIVE_SECOND_TIMEOUT_MODELS,
  MESSAGE_TIMEOUT_ERROR,
  MESSAGE_TYPE_JSON,
} from "../../../../../constants";
import { Images } from "../../../../../theme";
import { Loader } from "../../../../../components";
import clsx from "clsx";
// import { navigateToLogin } from "../../../../../utils";
import {
  deleteUploadMediaRequest,
  generateUploadURLRequest,
  getMediaStatusRequest,
  uploadMediaRequest,
} from "../../../../../redux/slicers/general";
import {
  convertObjectToFormData,
  generateGuid,
  promptFilesValidation,
} from "../../../../../utils";
import "./styles.scss";

const ChatBox = ({
  selectedModel,
  selectedPromptMode,
  isProjectApp,
  // restrictAsuData,
  searchFromMyFiles,
  appConfig,
  projectId,
  audioPreviewHandler,
  selectedMedia = [],
}) => {
  // STATES
  const [sessionId, setSessionId] = useState(null);
  const [reversedScrolling, setReversedScrolling] = useState(false);
  const [initLoad, setinitLoad] = useState(true);
  const [scrollToKey, setScrollToKey] = useState();
  const [moreMessagessData, setmoreMessagesData] = useState(null);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState({});
  const [showToast, setShowToast] = useState(false);

  // CUSTOM DISPATCH
  const [createChat, chatLoader] = CustomDispatch(createChatRequest);
  const [getMessages, messgesLoader] = CustomDispatch(getMessagesRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);
  const [generateUrl] = CustomDispatch(generateUploadURLRequest);
  const [getMediaStatus] = CustomDispatch(getMediaStatusRequest);
  const [deleteMedia] = CustomDispatch(deleteUploadMediaRequest);

  // REDUX DATA
  const messages = useSelector((state) => state.chat.messages);
  const projectData = useSelector(({ project }) => project.projectData);
  const modelOptions = useSelector(({ general }) => general.models);

  // CONST VALS
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const messageBox = useRef(null);
  const messageHistory = useRef(null);
  const toastTimerRef = useRef(null);
  const messageWrapper = useRef(null);
  const itemRefs = useRef({});
  const { sendJsonMessage, lastMessage, getWebSocket } = WebSocketHandlers();
  const topK = searchParams.get("topk");
  const currentChat = searchParams.get("chat");
  const imageSupportedModel = modelOptions.find((x) => x.imageSupport);
  const audioSupportedModel = modelOptions.find((x) => x.audioSupport);
  const splashScreenTitle = isProjectApp
    ? projectData?.splashText ?? appConfig?.splashScreenTitle
    : appConfig?.splashScreenTitle;

  // HELPERS
  const getUploadedMediaStatus = () => {
    const appPayload = {
      client_method: "list_assets",
      session_id: currentChat ?? sessionId,
      query_id: selectedQuery,
    };
    const projectApppayload = {
      resource: "data",
      method: "list_assets",
      details: {
        session_id: currentChat ?? sessionId,
        project_id: projectId,
        db_type: "opensearch",
        query_id: selectedQuery,
      },
    };
    const payload = isProjectApp ? projectApppayload : appPayload;
    getMediaStatus({
      payload,
      otherkeys: { isProjectApp },
      success: ({ data }) => {
        setSelectedFile((prevFiles) =>
          prevFiles.map((file) => {
            const newfile = data.files.find((x) => x.file_name === file.name);
            const fileStatus = newfile?.search_status;
            if (fileStatus === "not_searchable") {
              return {
                ...file,
                error:
                  "There was an error uploading your file. Please try again or contact support at ai-acceleration@asu.edu if the issue persists.",
              };
            }
            return file;
          })
        );
        setFileLoading((prevLoading) => {
          const temp = { ...prevLoading };
          Object.keys(prevLoading).forEach((key) => {
            const file = data.files.find((x) => x.file_name === key);
            const fileStatus = file?.search_status;
            if (
              fileStatus === "searchable" ||
              fileStatus === "not_searchable"
            ) {
              delete temp[key];
            }
          });
          return temp;
        });
      },
    });
  };

  const uploadMediaHelper = (data, files) => {
    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        otherkeys: { url: filedata.url },
        payload,
        success: () => {
          setuploadedFiles((prevFiles) => [...prevFiles, filedata]);
        },
      });
    });
  };

  const generateUrlHelper = (files) => {
    const projectAppPayload = {
      resource: "data",
      method: "chat_upload",
      details: {
        db_type: "opensearch",
        project_id: projectId,
        session_id: currentChat ?? sessionId,
        files: files.map((item) => {
          return { file_name: item.name };
        }),
      },
    };
    const appPayload = {
      files: files.map((item) => item.name),
      client_method: "chat_upload",
      session_id: currentChat ?? sessionId,
    };

    if (selectedQuery) projectAppPayload.details["query_id"] = selectedQuery;
    if (selectedQuery) appPayload["query_id"] = selectedQuery;

    generateUrl({
      payload: isProjectApp ? projectAppPayload : appPayload,
      otherkeys: { isProjectApp, promptMedia: true },
      success: (data) => {
        setSelectedQuery(data.queryId);
        uploadMediaHelper(data.files, files);
        setSelectedFile((prevFiles) =>
          prevFiles.map((file) => {
            const matchedFile = data.errorFiles[file.name];
            return matchedFile
              ? { ...file, error: data.errorFiles[file.name] }
              : file;
          })
        );
        setFileLoading((prevLoading) => {
          const temp = { ...prevLoading };
          Object.keys(prevLoading).forEach((key) => {
            if (!data.files.some((x) => x.name === key)) {
              delete temp[key];
            }
          });
          return temp;
        });
      },
      error: () => {
        setSelectedFile([]);
        setFileLoading({});
        setuploadedFiles([]);
      },
    });
  };
  const stopResponseHelper = (data, closeConnection = true) => {
    setLoading(false);
    if (closeConnection) getWebSocket().close();
    if (messageHistory.current) messageHistory.current["isLoading"] = false;
    dispatch(recieveMessageRequest(data));
    messageHistory.current = null;
  };
  const generateMessagePayloadHelper = ({
    message = "",
    isError = false,
    isLoading = false,
    files = selectedFile,
    model,
  }) => {
    const botName = appConfig?.botname ?? "Bot";
    const images = files.filter((x) => x.type === "image");
    const audio = files.filter((x) => x.type === "audio");
    const notImageSupprted = images.length > 0 && !selectedModel.imageSupport;
    const notAudioSupprted = audio.length > 0 && !selectedModel.audioSupport;
    const suportedmodel = notImageSupprted
      ? imageSupportedModel
      : notAudioSupprted
      ? audioSupportedModel
      : model
      ? { label: model }
      : selectedModel;
    const payload = {
      id: selectedQuery,
      isCompleted: false,
      isLoading: isError ? false : isLoading,
      isPrompt: true,
      model: isProjectApp
        ? projectData?.botName
        : appConfig?.modelselector
        ? suportedmodel.label
        : botName,
      message,
    };
    if (isError) payload["isError"] = true;
    return payload;
  };
  const sendImageMessageHelper = (message, images, session, queryId) => {
    const uploadedimages = images.filter((x) => x.type === "image");
    const uploadeddocs = images.filter(
      (x) => x.type !== "image" && x.type !== "audio"
    );
    const uploadedaudio = images.filter((x) => x.type === "audio");
    const notImageSupprted =
      uploadedimages.length > 0 && !selectedModel.imageSupport;
    const notAudioSupprted =
      uploadedaudio.length > 0 && !selectedModel.audioSupport;
    const model = notImageSupprted
      ? imageSupportedModel
      : notAudioSupprted
      ? audioSupportedModel
      : selectedModel;
    const payload = {
      action: "queryV2",
      session_id: session ?? currentChat ?? sessionId,
      query_id: queryId ?? selectedQuery,
      query: message,
      chat_upload: {
        images: uploadedimages.map((x) => x.name),
        docs: uploadeddocs.map((x) => x.name),
        audios: uploadedaudio.map((x) => x.name),
      },
    };
    if (isProjectApp) {
      payload["project_id"] = projectId;
    } else {
      payload["model_provider"] = model.provider;
      payload["model_name"] = model.value;
      payload["enable_search"] = false;
      payload["enable_history"] = false;
    }
    if (uploadedimages.length >= 1) {
      payload["request_type"] = "vision";
      payload["endpoint"] = "vision";
    }
    if (uploadedaudio.length >= 1) {
      payload["request_type"] = "audio";
      payload["endpoint"] = "audio";
    }
    if (MESSAGE_TYPE_JSON) {
      payload["response_format"] = { type: "json" };
    }
    sendJsonMessage(payload);
    setSelectedQuery(null);
  };
  const sendMessageHelper = (message, session) => {
    startToastTimer();
    const projectAppMessagePayload = {
      action: "queryV2",
      session_id: session ?? currentChat ?? sessionId,
      query: message,
      project_id: projectId,
      enable_history: true,
      search_params: {},
    };
    const appMessagePayload = {
      action: "queryV2",
      session_id: session ?? currentChat ?? sessionId,
      model_provider: selectedModel.provider,
      model_name: selectedModel.value,
      prompt: message,
      // enable_search: restrictAsuData || searchFromMyFiles,
      enable_search: searchFromMyFiles,
      enable_history: true,
      search_params: {},
    };

    const messagePayload = isProjectApp
      ? projectAppMessagePayload
      : appMessagePayload;

    if (searchFromMyFiles || !appConfig?.uploadfiles) {
      messagePayload.search_params = {
        ...messagePayload.search_params,
        prompt_mode: selectedPromptMode.value,
        collection: appConfig?.collection,
        tags: [],
        files: selectedMedia,
        top_k: topK ?? appConfig?.top_k,
      };
    }

    if (MESSAGE_TYPE_JSON) {
      messagePayload["response_format"] = { type: "json" };
    }
    // needs to remove project app logic once asugpt issue is fixed
    if (isProjectApp && MESSAGE_TYPE_JSON) {
      messagePayload.search_params = {
        ...messagePayload.search_params,
        output_fields: ["source_name", "page_number", "tags", "url"],
      };
    }

    // send message to websocket
    sendJsonMessage(messagePayload);
  };
  const createChatHelper = (message, images, queryId) => {
    const name = message.split("\n")[0];
    const chatName = `${name.slice(0, 30)}${name.length > 30 ? "..." : ""}`;
    const payload = {
      method: "create",
      details: {
        app_id: isProjectApp ? projectId : appConfig.id,
        session_name: chatName,
      },
    };
    if (images.length >= 1) payload.details["session_id"] = sessionId;
    createChat({
      payload,
      success: (res) => {
        images.length >= 1
          ? sendImageMessageHelper(message, images, res?.id, queryId)
          : sendMessageHelper(message, res?.id);
        setSearchParams({ chat: res?.id });
      },
    });
  };
  const getMessagesHelper = () => {
    const payload = {
      method: "describe",
      details: {
        session_id: currentChat,
        app_id: isProjectApp ? projectId : appConfig.id,
      },
      item_limit: 30,
    };
    if (moreMessagessData) payload["next_page"] = moreMessagessData;
    getMessages({
      payload,
      otherkeys: generateMessagePayloadHelper({}),
      success: (data) => {
        const isReversed = data.messages?.messages?.length >= 8;
        setReversedScrolling(isReversed);
        setScrollToKey(messages?.[0]?.id);
        setmoreMessagesData(data?.nextPageData);
        if (!isReversed) scrollToBottom();
      },
      error: () => {
        setSearchParams({});
      },
    });
  };

  // HANDLERS
  const audioBoxPreviewHandler = () => {
    audioPreviewHandler();
    setSelectedFile([]);
    setuploadedFiles([]);
  };

  const scrollToBottom = () => {
    messageBox.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const scrolledToTop = Math.abs(scrollTop) + clientHeight === scrollHeight;
      setinitLoad(false);
      if (!scrolledToTop) return;
      if (!moreMessagessData || messgesLoader) return;
      getMessagesHelper();
    },
    [getMessagesHelper, messgesLoader]
  );

  const selectFileHandler = (files) => {
    const newFileLoadingState = {};
    const promises = Array.from(files).map((file) => {
      const fileName = file.name;
      const fileType = file.type;
      const isImage = fileType.includes("image");
      const isAudio = fileType.includes("audio");
      const error = promptFilesValidation(file, true);

      if (error) {
        const mediaObj = {
          type: isImage ? "image" : isAudio ? "audio" : "doc",
          data: file,
          name: fileName,
          error: error,
        };
        setSelectedFile((prevFiles) => [...prevFiles, mediaObj]);
        return Promise.reject(mediaObj);
      }

      newFileLoadingState[fileName] = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        setFileLoading((prevLoading) => ({
          ...prevLoading,
          ...newFileLoadingState,
        }));

        reader.onload = (e) => {
          resolve({ type: "image", data: e.target.result, name: fileName });
        };
        reader.onerror = (e) => reject(e);

        if (isImage) {
          reader.readAsDataURL(file);
        } else {
          resolve({
            type: isAudio ? "audio" : "doc",
            data: file,
            name: fileName,
          });
        }
      });
    });

    Promise.all(promises)
      .then((results) => {
        setSelectedFile((prevFiles) => [...prevFiles, ...results]);
        generateUrlHelper(files);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const removeSelectedFileHandler = (file) => {
    const appPayload = {
      client_method: "delete_assets",
      session_id: currentChat ?? sessionId,
      query_id: selectedQuery,
      files: [file],
    };
    const projectPayload = {
      resource: "data",
      method: "delete_assets",
      details: {
        session_id: currentChat ?? sessionId,
        query_id: selectedQuery,
        project_id: projectId,
        db_type: "opensearch",
        files: [{ file_name: file }],
      },
    };
    const payload = isProjectApp ? projectPayload : appPayload;
    setSelectedFile((prevFiles) => prevFiles.filter((x) => x.name !== file));
    setuploadedFiles((prevFiles) => prevFiles.filter((x) => x.name !== file));
    setFileLoading(({ [file]: _, ...rest }) => rest);
    deleteMedia({ payload, otherkeys: { isProjectApp } });
  };

  const sendMessageHandler = (message, images, queryId) => {
    const uploadedFiles = images || selectedFile;
    const promptImages = uploadedFiles.filter((x) => !x.error);
    setSelectedFile([]);
    setuploadedFiles([]);

    // save message to reducer
    const usermessage = {
      message,
      images: promptImages,
    };

    if (queryId || selectedQuery) {
      usermessage["queryId"] = queryId || selectedQuery;
    }

    dispatch(sendMessageRequest(usermessage));

    // save message to history
    const payload = generateMessagePayloadHelper({
      isLoading: true,
      files: promptImages,
    });
    messageHistory.current = payload;

    // scroll to bottom
    scrollToBottom();

    setLoading(true);
    if (!currentChat) {
      createChatHelper(message, promptImages, queryId);
      return;
    }
    // send image message
    if (promptImages.length >= 1) {
      sendImageMessageHelper(message, promptImages, null, queryId);
      return;
    }
    // else send text message
    sendMessageHelper(message);
  };

  const stopResponseGenerating = () => {
    stopResponseHelper(messageHistory.current);
  };

  const startToastTimer = () => {
    clearToastTimer();
    let timeoutDuration = MESSAGE_TIMEOUT * 1000; // Default

    if (THREE_MINUTE_TIMEOUT_MODELS.includes(selectedModel.value))
      timeoutDuration = MESSAGE_TIMEOUT * 6 * 1000;
    else if (FORTY_FIVE_SECOND_TIMEOUT_MODELS.includes(selectedModel.value))
      timeoutDuration = MESSAGE_TIMEOUT * 1.5 * 1000;
    toastTimerRef.current = setTimeout(() => {
      setShowToast(true);
    }, timeoutDuration);
  };

  const clearToastTimer = () => {
    if (toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = null;
    }
  };

  // MANIPULATORS
  const manipulateJsonMessage = (lastMessage) => {
    const data = JSON.parse(lastMessage);
    const message = data?.response;
    if ("message" in data) return;

    // need to check if error is in data
    if ("error" in data) {
      stopResponseHelper(
        generateMessagePayloadHelper({
          model: messageHistory.current.model,
          message: data.error,
          isError: true,
        })
      );
      return;
    }
    if (message?.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      messageHistory.current["sources"] = data?.metadata?.sources ?? [];
      messageHistory.current["id"] = data?.metadata?.query_id;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if ("message" in data) return;
    const updatedmessage = messageHistory.current.message.concat(message);
    messageHistory.current = generateMessagePayloadHelper({
      model: messageHistory.current.model,
      message: updatedmessage,
      isLoading: false,
    });
  };

  const manipulateMessage = (lastMessage) => {
    if (lastMessage.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if (lastMessage.includes(`message":`)) return;
    const message = messageHistory.current.message.concat(lastMessage);
    messageHistory.current = generateMessagePayloadHelper({
      model: messageHistory.current.model,
      message,
      isLoading: false,
    });
  };

  // HOOKS
  useMemo(() => {
    if (currentChat) return;
    setSessionId(generateGuid());
  }, [currentChat]);

  useLayoutEffect(() => {
    if (!scrollToKey || messageWrapper.current.scrollTop > 0 || initLoad)
      return;
    itemRefs.current?.[scrollToKey]?.scrollIntoView();
  }, [scrollToKey]);

  useEffect(() => {
    if (!showToast) return;
    stopResponseHelper(
      generateMessagePayloadHelper({
        model: messageHistory.current.model,
        message: MESSAGE_TIMEOUT_ERROR,
        isError: true,
      })
    );
    setShowToast(false);
  }, [showToast]);

  useMemo(() => {
    if (!currentChat) {
      dispatch(clearMessages());
      getWebSocket()?.close();
      return;
    }
    if (!lastMessage) return;
    clearToastTimer();
    // if (lastMessage.includes(`message":`)) {
    //   // navigateToLogin()
    //   return;
    // }

    // manipulate message based on type
    if (MESSAGE_TYPE_JSON) return manipulateJsonMessage(lastMessage.data);

    manipulateMessage(lastMessage.data);
  }, [lastMessage]);

  useEffect(() => {
    setinitLoad(true);
    setmoreMessagesData(null);
    if (isLoading) return;
    if (currentChat) {
      getMessagesHelper();
      return;
    }
    dispatch(clearMessages());
  }, [currentChat]);
  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const selectedFiles = selectedFile.filter((x) => !x.error);
      const pendingMedia = uploadedFiles.length >= selectedFiles.length;
      if (Object.keys(fileLoading).length > 0 && pendingMedia) {
        interval = setInterval(() => {
          getUploadedMediaStatus(true);
        }, 2000); // 2 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [uploadedFiles, fileLoading, selectedQuery]);

  // CUSTOM COMPONENT
  const PromptThumb = () => (
    <img className="logo" src={Images.LogoThumb} alt="" />
  );

  return (
    <div
      className={clsx("chat-box", selectedFile.length >= 1 && "selected")}
      data-testid="chat-wrapper"
    >
      <div
        className={clsx("messages-wrapper", reversedScrolling && "reversed")}
        ref={messageWrapper}
        onScroll={handleScroll}
      >
        {messgesLoader && !moreMessagessData ? (
          <Loader height="100%" />
        ) : (
          <>
            {reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
            {messages.length >= 1 ? (
              <div className="messages-list" data-testid="messages-list">
                {moreMessagessData && messgesLoader && (
                  <Loader
                    height="30px"
                    size={10}
                    style={{ position: "absolute", top: 10, width: "100%" }}
                  />
                )}
                {messages.map((message, index) => (
                  <MessageBox
                    key={index}
                    data={message}
                    ref={(ref) => (itemRefs.current[message.id] = ref)}
                    previousMessage={messages[index - 1]}
                    isLoading={isLoading || chatLoader}
                    reGenerateMessage={sendMessageHandler}
                    promptThumb={<PromptThumb />}
                  />
                ))}
                {messageHistory.current && (
                  <MessageBox
                    data={messageHistory.current}
                    scrollToBottom={scrollToBottom}
                    promptThumb={<PromptThumb />}
                  />
                )}
              </div>
            ) : (
              <>
                {isProjectApp ? (
                  <SplashScreen sendMessage={sendMessageHandler} />
                ) : (
                  <div className="splash-box" data-testid="splash-box">
                    <img src={Images.AiLoader} alt="" />
                    <h3>{splashScreenTitle}</h3>
                  </div>
                )}
              </>
            )}
            {!reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
          </>
        )}
      </div>
      <div className="bottom-wrapper">
        <InputBox
          ref={messageWrapper}
          audioBoxPreviewHandler={audioBoxPreviewHandler}
          selectFile={selectFileHandler}
          removeSelectedFile={removeSelectedFileHandler}
          selectedModel={selectedModel}
          appConfig={appConfig}
          projectId={projectId}
          isProjectApp={isProjectApp}
          projectData={projectData}
          sendMessage={sendMessageHandler}
          isLoading={isLoading || chatLoader}
          fileLoading={fileLoading}
          stopResponseGenerating={stopResponseGenerating}
          selectedFile={selectedFile}
          setFileLoading={setFileLoading}
        />
      </div>
    </div>
  );
};

export default ChatBox;
